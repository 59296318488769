import * as React from "react"
// global components
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/general/page-header"
import JSONData from "../data/texts.json"

const pageData = JSONData.pages.podcast

const PodcastPage = () => (
  <Layout>
    <Seo title="Podcast" />
    <PageHeader texts={pageData} />
    <div>
      <ul className="flex justify-center">
        <li>
          <a class="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded-full no-underline"
            href="https://open.spotify.com/show/6gXLL6HDmFmfdZvbIeJ7Lw?si=bde2629caeff46e9"
          >Spotify</a>
        </li>
      </ul>
    </div>
    <div>
      <iframe src="https://embed.acast.com/65d46dfc947e970016209734?theme=light&feed=true" frameBorder="0" width="100%" height="380px"></iframe>
    </div>
  </Layout>
)

export default PodcastPage
